'use client'

import {
  dehydrate,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { SessionProvider } from 'next-auth/react'
import * as React from 'react'
import type { Session } from 'next-auth'
import { Toaster } from '@viewpoint/ui'
import { useSearchParams } from 'next/navigation'
import { BreakpointProvider, EnableDeveloperToolsProvider } from '@hooks'
import { ThemeProvider } from '@/components/providers'
import { isValidUrl } from '@/lib/utils'

export function Layout({
  children,
  session,
}: React.PropsWithChildren<{
  session: Session | null
}>): JSX.Element {
  const [queryClient] = React.useState(() => new QueryClient())
  const dehydratedState = dehydrate(queryClient)
  const searchParams = useSearchParams()

  const pageOnly = searchParams.get('page_only') === 'true'

  const url =
    session?.vpnextUrl &&
    isValidUrl(session.vpnextUrl) &&
    new URL(session.vpnextUrl)
  const urlPath = url ? url.pathname : '/'
  const nextAuthUrlPath = urlPath.endsWith('/')
    ? `${urlPath}api/auth`
    : `${urlPath}/api/auth`

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="system"
      disableTransitionOnChange
      enableSystem
      forcedTheme={pageOnly ? 'light' : undefined}
    >
      <SessionProvider basePath={nextAuthUrlPath} session={session}>
        <BreakpointProvider>
          <EnableDeveloperToolsProvider>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={dehydratedState}>{children}</Hydrate>
            </QueryClientProvider>
            <Toaster />
          </EnableDeveloperToolsProvider>
        </BreakpointProvider>
      </SessionProvider>
    </ThemeProvider>
  )
}
